import api from '@/services/gateway/api';

const state = {
  list: [],
  listLength: 0,
  megaliner: {},
  queueList: [],
  queueListLength: 0
};

const getters = {
  getList: state => state.list,
  getListLength: state => state.listLength,
  getMegaliner: state => state.megaliner,
  getQueueList: state => state.queueList,
  getQueueListLength: state => state.queueListLength
};

const mutations = {
  setList (state, data) {
    state.list = data;
  },
  setlistLength (state, data) {
    state.listLength = data;
  },
  setItem (state, data) {
    state.megaliner = data;
  },
  setQueueList (state, data) {
    state.queueList = data;
  },
  setQueueListLength (state, data) {
    state.queueListLength = data;
  }
};

const actions = {
  actionGetList ({ commit }, payload) {
    return api.get('camp/sapa/users/all', { params: payload }).then((response) => {
      commit('setList', response.data.data);
      commit('setlistLength', response.data.meta.total);
    }
    ).catch(() => {});
  },
  actionGetMegaliner ({ commit }, id) {
    return api.get(`camp/sapa/users/${id}`).then((response) => {
      commit('setItem', response.data.data);
    }
    ).catch(() => {});
  },
  actionGetQueueList ({ commit }) {
    return api.get('camp/sapa/requests/queue').then((response) => {
      const data = Object.entries(response.data.data).map(([key, value]) => ({
        filial_id: key ? parseInt(key.replace('filial_', ''), 10) : 0,
        ...value
      }));

      commit('setQueueList', data);
      commit('setQueueListLength', response.data.meta.total);
    }
    ).catch(() => {});
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
